import { AMakeServerTimeChart, ASetServerChart } from "../../classes/ADeviceCharts.js";
import { ADetectionStateSnapshot } from "../../classes/ADetectionStateSnapshot.js";
import { AError } from "../../classes/AError.js";
import { ATemplates } from "../../core/ATemplateService.js";
export class APage {
    constructor() {
        this.StateChart = null;
        this.ServerChart = null;
        $('#RefreshButton').on('click', _ => FilterManager.showFilterWarning().then(_ => this.refresh()));
        this.ServerChart = null;
        this.StateChart = null;
        this.detectionStateSnapshot = new ADetectionStateSnapshot({ interval: 5 });
    }
    async init() {
        FilterManager.load();
        // this.ServerChartOptions = await AGetServerChartOptions();
        this.ServerChart = await AMakeServerTimeChart("ServerChart");
        this.ServerChart.reflow();
        this.StateChart = await this.detectionStateSnapshot.makeTimeChart("StateChart", { viewType: 'operational' });
        this.StateChart.reflow();
        return true;
    }
    async refresh() {
        try {
            if (this.ServerChart == null) {
                throw new Error(`PageScript.ServerChart is not defined!`);
            }
            if (this.StateChart == null) {
                throw new Error(`PageScript.StateChart is not defined!`);
            }
            FilterManager.setActive(false);
            let { FromDate, ToDate } = FilterManager.save();
            await Loading.waitForPromises([
                ASetServerChart(this.ServerChart, new Date(FromDate).getTime(), new Date(ToDate).getTime()),
                this.detectionStateSnapshot.update(new Date(FromDate), new Date(ToDate))
            ]).then(_ => {
                this.ServerChart.reflow();
                this.StateChart.reflow();
            });
        }
        catch (err) {
            AError.handle(err);
        }
        finally {
            FilterManager.setActive(true);
            const $displayOnce = $('.display-once');
            $displayOnce.remove();
            $('[aci-show-on-input]').removeClass('hidden');
        }
    }
}
export function css() {
    return ( /*html*/`
    <style>
      #ServerChart {
        width: 80%;
        height: 400px;
        overflow: hidden;
        margin: 15px auto 15px auto;
      }
      #StateChart {
        width: 80%;
        height: 400px;
        overflow: hidden;
        margin: 15px auto 15px auto;
      }
    </style>
  `);
}
export function render() {
    return ( /*html*/`
    <div id="Filters" class="filter-bar side-filter-bar columns">
      <div class="column c-scroll col-12">
        <div class="form-group">
          <label class="form-label" for="FromDate">From</label>
          <input class="form-input" type="date" id="FromDate" required="required">
          <input class="form-input" type="time" id="FromTime" required="required">
        </div>

        <div class="form-group">
          <label class="form-label" for="ToDate">To</label>
          <input class="form-input" type="date" id="ToDate" required="required">
          <input class="form-input" type="time" id="ToTime" required="required">
        </div>

        <div class="form-group">
          <label class="form-label" for="Limit">Max results</label>
          <input class="form-input" type="number" id="Limit" value="2000">
        </div>
      </div>
      <div class="column col-12">
        <button class="btn btn-primary col-12" id="RefreshButton">Show</button>
      </div>
    </div>
    <div class="flex-child fh" style="overflow-y: auto;">
      <div class="flex-content">
        <div template="${ATemplates.WaitingForInput}"></div>
        <div aci-show-on-input="true" class="hidden text-center">
          <div id="ServerChart"></div>
          <div id="StateChart"></div>
        </div>
      </div>
    </div>
  `);
}
